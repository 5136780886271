.iconsWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 4px;

	.icon-height {
		height: 22px;
	}
}

.ratingParent {
	position: absolute;
	bottom: 10px;
	right: 10px;
	color: var(--color-stars);
	background-color: white;
	border-radius: 5px;
	display: flex;
	align-items: center;
	z-index: 1;

	.iconsWrapper {
		margin: 0;
		padding: 8px;


		.mr-1 {
			height: 19px;
			position: relative;
			top: -4px;
		}
	}
}