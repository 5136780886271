.index-slideshow-v1-wrapper {
	postion: relative;

	.covid-btn {
		position: absolute;
		background: var(--color-primary);
		color: white;
		font-weight: 500;
		border-radius: 100%;
		height: 120px;
		width: 120px;
		text-align: center;
		position: absolute;
		bottom: 10px;
		z-index: 1;
		right: 10px;
		font-size: 15px;

		@media (max-width: 992px) {
			height: 75px;
			width: 75px;
			font-size: 10px;
		}
	}

	#covid-modal {
		.modal-dialog {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 50%;
		}


		.modal-title {
			margin-bottom: 10px;
			font-size: var(--h3-font-size);
		}

		.modal-content {
			padding: 20px;
		}

		.modal-body {
			padding: 0;
			font-size: var(--font-size-md)
		}

		.modal-footer {
			border: none;
		}
	}

	.index-slide-show-v1 {
		position: relative;
		height: var(--index-slide-show-height);
		min-height: var(--index-slide-show-height);

		@media (max-height: 600px) {
			min-height: 680px;

		}

		.owl-carousel {
			z-index: unset;
		}


		.index-slide {
			height: var(--index-slide-show-height);
			margin-top: var(--index-slide-show-margin-top);

			.item {
				height: var(--index-slide-show-height);
				overflow: hidden;

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}

			.slide-item {
				height: var(--index-slide-show-height);
				min-height: var(--index-slide-show-height);
				position: relative;
				background-repeat: no-repeat;
				background-position: top center;
				background-size: cover;
			}

			.picture {
				height: var(--index-slide-show-height);
				min-height: var(--index-slide-show-height);

				@media (max-height: 600px) {
					min-height: 680px;

				}

				.img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}

		.carousel-item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			@media (max-height: 600px) {
				min-height: 680px;

			}
		}

		.carousel-item img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

		.slide1 {
			background-image: url(../img/slide1-1980.jpg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.hero-header {
			display: none;
			position: absolute;
			top: 25vh;
			font-family: 'Poppins', cursive;
			;
			width: 100%;
			z-index: 3;
			padding: 0 5px;

			@media (max-height: 600px) {
				top: 200px;

			}

			.hero-text {
				text-align: center;
				width: 100%;

				.hero-headline {
					width: 100%;
				}

				h1 {
					font-family: var(--hero-header-font-family);
					font-size: 5vw;
					color: #fff;
					text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

					font-weight: 400;
					margin-bottom: 0px;

					@media (min-width: 1600px) {
						font-size: 80px;
					}

					@media (max-width: 1140px) {
						font-size: 57px;
					}

					@media (max-width: 992px) {
						font-size: 4rem;
						line-height: 4rem;
					}

					@media (max-width: 576px) {
						font-size: 3rem;
						line-height: 3rem;
					}

					@media (max-height: 600px) {
						font-size: 2.75rem;
						line-height: 2.75rem;

					}

				}

				.hero-subtitle {
					font-family: var(--hero-header-sub-title-font-family);
					font-size: 2.5rem;
					color: #fff;
					text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

					@media (min-width: 1600px) {
						font-size: 2.5rem;
					}

					@media (max-width: 1140px) {
						font-size: 2.0rem;
					}

					@media (max-width: 992px) {
						font-size: 2.0rem;
					}

					@media (max-width: 575px) {
						font-size: 1.5rem;
					}
				}
			}

		}
	}
}