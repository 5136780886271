.unit-page-layout-v1 {
	margin-top: 148px;

	@media(max-width:1199px) {
		margin-top: 150px;
	}

	@media(max-width:767px) {
		margin-top: 190px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-secondary-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-rat {
		position: absolute;
		top: 482px;
		right: 30px;
		background-color: var(--color-white);
		border-radius: 5px;
		padding: 5px;

		@media (max-width:767px) {
			top: 345px;
		}

	}
}