.blog-page-layout-v1 {

	margin-top: 148px;

	@media(max-width:1199px) {
		margin-top: 150px;
	}

	@media(max-width:767px) {
		margin-top: 190px;
	}
}