.parallax-v2 {
	position: relative;

	background-image: url(RESOURCE/img/luftaufnahme_img.jpg);


	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	.parallax-box {
		background-color: var(--color-secondary);
		padding: 20px;
		min-height: 400px;

		.title {
			color: var(--color-white);

		}

		.text {
			color: var(--color-white);
		}
	}

}