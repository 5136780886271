.parallax-v1 {
	position: relative;
	background-image: url(RESOURCE/img/sunset-img.jpg);


	background-attachment: fixed;
	background-position: left;
	background-repeat: no-repeat;
	background-size: 130%;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	.parallax-box {
		background-color: var(--color-primary);
		padding: 20px;
		min-height: 300px;

		.title {
			color: var(--color-white);

		}

		.text {
			color: var(--color-white);
		}
	}

}